module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"SaltSmart","short_name":"SaltSmart","start_url":"/","background_color":"#fff","theme_color":"#00ACED","display":"standalone","lang":"en","orientation":"portrait","icon":"src/images/Compass_Salt_appIcon.png","icons":[{"src":"/apple-touch-icon.png","sizes":"512x512","type":"image/png"}],"legacy":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-analytics/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"stylesProvider":{"injectFirst":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"GTM-5XX77PD","head":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"appendScript":"src/add-to-sw-code.js","precachePages":["/guest/*/**","/us/*/**","/static/*/**","/portfolios/*"],"workboxConfig":{"cacheId":"saltSmart-offline","globPatterns":["**/*.{js,jpg,png,html,css,json}"],"skipWaiting":true,"clientsClaim":true}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
