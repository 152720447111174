/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
// ./gatsby-browser.js
import "./src/styles/grid.scss";
import "./src/styles/style.scss";
import "./src/styles/nav.scss";
export const registerServiceWorker = () => true
/*
if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('workbox-sw.js').then(registration => {
      console.log('SW registered: ', registration);
    }).catch(registrationError => {
      console.log('SW registration failed: ', registrationError);
    });
  });
}
if ("serviceWorker" in navigator) {
  if (navigator.serviceWorker.controller) {
    console.log("Active service worker found");
  } else {
    // Register the service worker
    navigator.serviceWorker
      .register("worker-sw.js", {
        scope: "./"
      })
      .then(function (reg) {
        console.log("Service worker registered for scope: " + reg.scope);
      });
  }
}
*/
export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    `There has been an update to SaltSmart. ` +
      `Update to latest version?`
  )
  if (answer === true) {
    window.location.reload()
  }
}